<template>
  <b-sidebar
    id="add-new-brands-sidebar"
    :visible="isAddNewCategorySidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-category-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Tạo mới Nhóm dược lý</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <!-- Name -->
          <validation-provider #default="validationContext" name="name" rules="required">
            <b-form-group label="Nhóm dược lý">
              <b-form-input
                id="name"
                v-model="categoryData.name"
                autofocus
                class="mt-1"
                :state="getValidationState(validationContext)"
                placeholder="Nhập Nhóm dược lý"
              />

              <b-form-invalid-feedback>{{ validationContext.errors[0]?'Bạn cần điền Nhóm dược lý':'' }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-group label="Nhóm tiểu dược lý">
            <b-form-input
              v-for="(item,index) in categoryData.sub_pharmacologicals"
              :key="index"
              v-model="item.name"
              autofocus
              class="mt-1"
              placeholder="Nhập Nhóm tiểu dược lý"
            />
            <svg
              @click="handleAddSubPharmacological"
              class="mt-1 cursor-pointer"
              width="28"
              height="28"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 13H11V15C11 15.2652 11.1054 15.5196 11.2929 15.7071C11.4804 15.8946 11.7348 16 12 16C12.2652 16 12.5196 15.8946 12.7071 15.7071C12.8946 15.5196 13 15.2652 13 15V13H15C15.2652 13 15.5196 12.8946 15.7071 12.7071C15.8946 12.5196 16 12.2652 16 12C16 11.7348 15.8946 11.4804 15.7071 11.2929C15.5196 11.1054 15.2652 11 15 11H13V9C13 8.73478 12.8946 8.48043 12.7071 8.29289C12.5196 8.10536 12.2652 8 12 8C11.7348 8 11.4804 8.10536 11.2929 8.29289C11.1054 8.48043 11 8.73478 11 9V11H9C8.73478 11 8.48043 11.1054 8.29289 11.2929C8.10536 11.4804 8 11.7348 8 12C8 12.2652 8.10536 12.5196 8.29289 12.7071C8.48043 12.8946 8.73478 13 9 13ZM21 2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V21C2 21.2652 2.10536 21.5196 2.29289 21.7071C2.48043 21.8946 2.73478 22 3 22H21C21.2652 22 21.5196 21.8946 21.7071 21.7071C21.8946 21.5196 22 21.2652 22 21V3C22 2.73478 21.8946 2.48043 21.7071 2.29289C21.5196 2.10536 21.2652 2 21 2ZM20 20H4V4H20V20Z"
                fill="#20409B"
              />
            </svg>
          </b-form-group>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isLoading"
            >{{isUpdate?'Cập nhật':'Tạo'}}</b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >Huỷ</b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import { useToast } from "vue-toastification/composition";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  model: {
    prop: "isAddNewCategorySidebarActive",
    event: "update:is-add-new-category-sidebar-active"
  },
  props: {
    isAddNewCategorySidebarActive: {
      type: Boolean,
      required: true
    },
    isUpdate: Boolean,
    isLoading: Boolean,
    dataProp: Object
  },
  data() {
    return {
      required
    };
  },
  watch: {
    dataProp: {
      handler() {
        this.categoryData.name = this.dataProp?.name || "";
        this.categoryData.sub_pharmacologicals = this.dataProp
          ?.sub_pharmacologicals || [{ name: "" }];
      },
      deep: true
    }
  },
  mounted() {
    this.categoryData.name = this.dataProp?.name || "";
    this.categoryData.sub_pharmacologicals = this.dataProp
      ?.sub_pharmacologicals || [{ name: "" }];
  },
  methods: {
    handleAddSubPharmacological() {
      this.categoryData.sub_pharmacologicals.push({ name: "" });
    }
  },
  setup(props, { emit }) {
    const toast = useToast();
    const blankCategory = {
      name: "",
      sub_pharmacologicals: [{ name: "" }]
    };

    const categoryData = ref(JSON.parse(JSON.stringify(blankCategory)));
    const resetuserData = () => {
      categoryData.value = JSON.parse(JSON.stringify(blankCategory));
    };

    const onSubmit = () => {
      const payload = {
        ...categoryData.value
      };
      console.log(payload);
      props.isUpdate
        ? emit("onUpdate", { ...payload, id: props.dataProp.id })
        : emit("onAdd", payload);
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetuserData
    );

    return {
      toast,
      categoryData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm
    };
  }
};
</script>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-brands-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
